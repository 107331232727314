import React from 'react';
import { Link, Route, Routes, useParams } from 'react-router-dom';
import { ZohoCampaign } from 'shared/src/zoho-types';
import { BeeswaxLineItem } from 'shared/src/beeswax-types';
import { PendingChangesButton } from '../pending-changes/pending-changes-button';
import { PartialLineItem } from 'shared/src/line-item-types';
import { MediaBuyOverview } from './media-buy-overview-page';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobePointer } from '@fortawesome/pro-light-svg-icons';
import { useStrategyContext } from '../line-items/strategy-context';
import { RefreshMediaBuyButton } from './refresh-media-buy-button';
import { NavItem } from '../../components/navitem';
import { TopSection } from '../../components/layout';
import { CombinedMediaBuy } from '../../store/strategy-combiner';

export function MediaBuyPage() {
  const { strategy, campaign, refetch } = useStrategyContext();
  const { mediaBuyId, lineItemId } = useParams();

  const lineItem = strategy.line_items.find(li => li.id === lineItemId);
  const mediaBuy = lineItem?.media_buys.find(mb => mb.id === mediaBuyId);

  if (!lineItem || !mediaBuy) {
    throw new Error(`Line item with id ${lineItemId} not found`);
  }

  const beeswaxLineItem = BeeswaxLineItem.parse(mediaBuy.media_platform_entity?.raw_entity || {});

  return (
    <div className="flex w-full flex-col">
      <TopSection>
        <Breadcrumbs campaign={campaign} lineItem={lineItem} mediaBuy={mediaBuy} />
        <div className="flex-1" />
        <div className="flex items-end justify-between">
          <div className="flex items-end">
            <NavItem to="overview" label="Overview" />
            <NavItem to="settings" label="Settings" />
          </div>
          <div className="ml-auto flex space-x-4 pb-2">
            <RefreshMediaBuyButton mediaBuyId={mediaBuy.id} refetch={refetch} />
            <DeepLink beeswaxLineItem={beeswaxLineItem} />
          </div>
        </div>
      </TopSection>
      <PendingChangesButton />
      <Routes>
        <Route path="overview" element={<MediaBuyOverview mediaBuy={mediaBuy} />} />
        <Route path="settings" element={<MediaBuyOverview mediaBuy={mediaBuy} />} />
      </Routes>
    </div>
  );
}

function DeepLink(props: { beeswaxLineItem: BeeswaxLineItem }) {
  return (
    <a
      target="_blank"
      href={generateBeeswaxDeeplink(props.beeswaxLineItem)}
      className="text-xs font-light text-blue-600"
      rel="noreferrer">
      <FontAwesomeIcon className="mr-2" icon={faGlobePointer} />
      View on Platform
    </a>
  );
}

type BreadcrumbProps = {
  campaign: ZohoCampaign | undefined;
  lineItem: PartialLineItem;
  mediaBuy: CombinedMediaBuy;
};

function Breadcrumbs({ campaign, lineItem, mediaBuy }: BreadcrumbProps) {
  return (
    <div className="mt-6">
      <div className="flex text-sm font-light">
        <Link className="mr-1 hover:underline" to="/campaigns">
          All Campaigns
        </Link>
        <div className="mr-1">/</div>
        <Link
          className="mr-1 hover:underline"
          to={`/campaigns/${campaign?.id}/strategy/performance`}>
          {campaign?.Deal_Name || '-'}
        </Link>
        <div className="mr-1">/</div>
        <Link
          className="mr-1 hover:underline"
          to={`/campaigns/${campaign?.id}/strategy/lineitems/${lineItem.id}/overview`}>
          {lineItem.name || '-'}
        </Link>
        <div>/</div>
      </div>
      <div className="mt-2 text-2xl font-bold">{mediaBuy.name}</div>
    </div>
  );
}

function generateBeeswaxDeeplink(beeswaxLineItem: BeeswaxLineItem) {
  const { advertiser_id, campaign_id, line_item_id } = beeswaxLineItem;
  return `https://brkthru.beeswax.com/advertisers/${advertiser_id}/campaigns/${campaign_id}/line_items/${line_item_id}/edit?tab=general&subtabs=essential`;
}
