import React from 'react';
import { CombinedMediaBuy } from '../../store/strategy-combiner';
import { formatDate } from 'shared/src/date-utils';
import { clsx } from 'clsx';
import { faCode } from '@fortawesome/pro-light-svg-icons';
import { formatCurrencyNoDecimal } from '../../components/table-utils';
import { BeeswaxLineItem } from 'shared/src/beeswax-types';
import ReactJsonView from '@microlink/react-json-view';
import { MediaBuyPerformance } from './media-buy-performance';
import { ExpandableSidebarSection, PageContentSection, PageSection } from '../../components/layout';

type Props = {
  mediaBuy: CombinedMediaBuy;
};

export function MediaBuyOverview({ mediaBuy }: Props) {
  const beeswaxLineItem = BeeswaxLineItem.parse(mediaBuy.media_platform_entity?.raw_entity);

  return (
    <PageSection>
      <PageContentSection>
        <MediaBuyPlatformMetrics platformLineItem={beeswaxLineItem} />
        <MediaBuyPerformance />
        <MediaBuyPacingMetrics mediaBuy={mediaBuy} />
      </PageContentSection>
      <ExpandableSidebarSection
        sections={[
          {
            name: 'config',
            header: 'Config',
            icon: faCode,
            render: () => <RawConfigSection mediaBuy={mediaBuy} />
          }
        ]}
      />
    </PageSection>
  );
}

function RawConfigSection({ mediaBuy }: { mediaBuy: CombinedMediaBuy | undefined }) {
  return (
    <div className="flex h-full flex-col">
      <ReactJsonView src={mediaBuy?.media_platform_entity?.raw_entity || {}} />
    </div>
  );
}

type MediaBuyPlatformMetricsProps = {
  platformLineItem: BeeswaxLineItem | undefined;
};

function MediaBuyPlatformMetrics({ platformLineItem }: MediaBuyPlatformMetricsProps) {
  const price = platformLineItem?.line_item_budget
    ? formatCurrencyNoDecimal(platformLineItem?.line_item_budget)
    : undefined;

  return (
    <div className="mb-8 flex items-center justify-between">
      <MetricItem name="Platform" value={'Beeswax'} flex="flex-1" />
      <MetricItem name="Id" value={platformLineItem?.line_item_id.toString()} flex="flex-1" />
      <MetricItem name="Budget" value={price} />
      <MetricItem name="Status" value={platformLineItem?.active ? 'Active' : 'Inactive'} />
      <MetricItem name="Start Date" value={formatDate(platformLineItem?.start_date)} />
      <MetricItem name="End Date" value={formatDate(platformLineItem?.end_date)} noBorder />
    </div>
  );
}

function MediaBuyPacingMetrics({ mediaBuy }: { mediaBuy: CombinedMediaBuy | undefined }) {
  return (
    <div className="mb-8 flex items-center justify-between">
      <MetricItem name="% of Units Delivered" value={'0%'} flex="flex-1" />
      <MetricItem name="% of Days Elapsed" value={'0%'} />
      <MetricItem name="% of Budget Spent" value={'0%'} />
    </div>
  );
}

type MetricItemProps = {
  name: string;
  flex?: string;
  noBorder?: boolean;
  value?: string;
};

function MetricItem({ name, value, noBorder = false, flex }: MetricItemProps) {
  return (
    <div
      className={clsx(
        'mb-4 mr-4 flex-1 whitespace-nowrap pr-4',
        noBorder ? '' : 'border-r-[1px] border-gray-200'
      )}>
      <div className="mb-2 text-sm font-light">{name}</div>
      {value ? (
        <div className="text-xl">{value}</div>
      ) : (
        <div className="text-xl text-gray-400">--</div>
      )}
    </div>
  );
}
