import { z } from 'zod';

// TODO[mk] - change to draft and finalised

const mediaPlatformBase = z.object({
  id: z.string(),
  media_platform_id: z.number(),
  entity_id: z.string(),
  raw_entity: z.object({}).catchall(z.any()),
  entity_type: z.string(),
  entity_name: z.string()
});

export type MediaPlatformEntity = z.infer<typeof mediaPlatformBase> & {
  parent: MediaPlatformEntity | null;
};

export const MediaPlatformEntity: z.ZodType<MediaPlatformEntity> = mediaPlatformBase.extend({
  parent: z.lazy(() => MediaPlatformEntity.nullable())
});

export const NewMediaPlatformEntity = mediaPlatformBase.extend({
  parent_id: z.string().nullable().optional()
});

export type NewMediaPlatformEntity = z.infer<typeof NewMediaPlatformEntity>;

export const UpdateMediaPlatformEntity = mediaPlatformBase;

export type UpdateMediaPlatformEntity = z.infer<typeof UpdateMediaPlatformEntity>;

export const MediaBuy = z.object({
  id: z.string(),
  // TODO this is redundant as its in the media_platform_entity
  name: z.string(),
  media_platform_id: z.number(),
  media_platform_entity: MediaPlatformEntity,
  start_date: z.coerce.date(),
  end_date: z.coerce.date(),
  budget: z.coerce.number()
});

export type MediaBuy = z.infer<typeof MediaBuy>;

export const LineItemMediaBuy = z.object({
  id: z.string(),
  line_item_id: z.string(),
  media_buy_id: z.string()
});
export type LineItemMediaBuy = z.infer<typeof LineItemMediaBuy>;

export const NewMediaBuy = MediaBuy.omit({
  media_platform_entity: true
}).extend({
  line_item_id: z.string()
});
export type NewMediaBuy = z.infer<typeof NewMediaBuy>;

export const MediaBuyUpdate = MediaBuy.partial()
  .omit({ id: true, media_platform_entity: true })
  .extend({ id: z.string() });

export type MediaBuyUpdate = z.infer<typeof MediaBuyUpdate>;

export const MediaBuyChanges = z.union([
  z.object({
    type: z.literal('new'),
    data: NewMediaBuy
  }),
  z.object({
    type: z.literal('update'),
    data: MediaBuyUpdate
  })
]);
export type MediaBuyChanges = z.infer<typeof MediaBuyChanges>;
